import * as React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { fetchAllCategories } from '../JS/THREEJS/AddImages.js';
import '../Assets/CSS/buttons.css';
import '../Assets/CSS/HomePage.css';
import { Image, NavLink } from 'react-bootstrap';
import { Link, Outlet } from 'react-router';
import ImageView from './ImageViewer.js';
import { Easing } from 'three/examples/jsm/libs/tween.module.js';

//const FAVS = ['Marvel', 'zachGamer', 'Zach&Emma5', 'SkyMountains', 'TheBoys23Logo']
export var ALL_IMGS;

export default class SplideCarousel extends React.Component {

    state = {
        ALL_IMGS: null,
        pageList: [],
        imgURLs: [],
        startIndex: 0,
        overflow: false
    }


    componentDidMount() {
        //StartImgServer();
        this.setState({ ALL_IMGS: fetchAllCategories() }, () => {
            var pages = [];
            Object.entries(this.state.ALL_IMGS).map((value, index, _array) => {
                pages.push(value);
                return null;
            })
            if (this.state.overflow) {
                document.querySelector('.appContainer').classList.remove('addOverflow');
                this.setState({ overflow: false, pageList: pages });
            }
            else {
                this.setState({ pageList: pages });
            }

        })

        document.addEventListener('readystatechange', (e) => {

            const el = document.querySelector('.vidSlide');

            el.parentElement.classList.add('vidUL');

            const vidLI = document.querySelector('.vrCarousel').parentElement;
            const slideUl = document.querySelector('.mainCarousel .splide_track ul');
            console.log('slide ul', slideUl)
            const videoObserver = new MutationObserver(this.VidAutoPlay);
            const slideObserver = new MutationObserver(this.slideAdjustments)
            const observerDefaultConfig = {attributes: true, attributeFilter: ['class'], attributeOldValue: true, subtree: true }
            videoObserver.observe(vidLI, observerDefaultConfig);
            //slideObserver.observer( , observerDefaultConfig)
            let imgSlides = document.querySelectorAll('.imgSlides');

            imgSlides.forEach(slide => {
                slide.addEventListener('mouseover', () => {
                    slide.ariaHidden = false;
                })
                slide.addEventListener('mouseout', () => {
                    slide.ariaHidden = true;
                })
            })
        })
    }

    slideAdjustments(mutationsList) {
        mutationsList.forEach(mutation => {

            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {

            }

        });
    }
    VidAutoPlay(mutationsList) {
        mutationsList.forEach(mutation => {

            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                console.log('old value', mutation);
                const vidLI = mutation.target;
                const oldClasses = mutation.oldValue.split(' ');
                const newClasses = vidLI.className.split(' ');

                // Detect added classes
                newClasses.forEach(cls => {
                    if (!oldClasses.includes(cls)) {
                        if (cls === 'is-active') {
                            var video = vidLI.querySelector('video');
                            video.play();
                        }
                    }
                });

                // Detect removed classes
                oldClasses.forEach(cls => {
                    if (!newClasses.includes(cls)) {
                        if (cls === 'is-active') {
                            var video = vidLI.querySelector('video');
                            video.pause();
                            video.currentTime = 0;
                        }
                    }
                });
            }
        });
    };

    render() {

        return (
            <div className='simpleHomeContnent'>
                <svg width="0" height="0" aria-hidden="true">
                    <filter id="grainy" x="0" y="0" width="100%" height="100%">
                        <feTurbulence type="fractalNoise" baseFrequency=".753"></feTurbulence>
                        <feColorMatrix type="saturate" values="0"></feColorMatrix>
                        <feComposite in="SourceGraphic" operator="in"></feComposite>
                    </filter>
                    <filter id="sinset">
                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                        <feComposite operator="out" in="SourceGraphic" result="inverse"></feComposite>
                        <feFlood floodColor="hsla(0, 0%, 100%, .375)"></feFlood>
                        <feComposite operator="in" in2="inverse"></feComposite>
                        <feComposite operator="over" in2="SourceGraphic"></feComposite>
                    </filter>
                    <filter id="emboss">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="9"></feGaussianBlur>
                        <feSpecularLighting surfaceScale="2" specularConstant=".75" specularExponent="17" lightingColor="#ededed">
                            <fePointLight x="0" y="-29000" z="20000"></fePointLight>
                        </feSpecularLighting>
                        <feComposite in2="SourceAlpha" operator="in"></feComposite>
                        <feComposite in="SourceGraphic" operator="arithmetic" k1="0" k2="1" k3="1" k4="0"></feComposite>
                    </filter>
                </svg>
                <Splide className='mainCarousel'
                    options={{
                        arrows: true,
                        autoplay: true,
                        //speed: 1000,
                        //interval: 1000,
                        rewind: true,                        
                        height: '100%',
                        drag: false,
                        type: 'fade', 
                        //easing: 'linear',
                       
                                          
                    }}
                    aria-label='Categories'>
                    {
                        this.state.pageList.length > 0 ?
                            this.state.pageList.map((object, index, _array) => (
                                <SplideSlide className='slidePage' key={index} aria-label={object[0]}>
                                    <NavLink as={Link} to={'/pages/' + object[0]} state={{ id: object[0] }} >
                                        <div className='categoryName'>{object[0]} </div>
                                    </NavLink>
                                    <Splide className={object[0] === 'VR' ? 'vrCarousel ' : "innerCarousel"} aria-label={object[0]}
                                        options={object[0] === 'VR' ? {
                                            arrows: false,
                                            rewind: true,
                                            type: 'slide',
                                            perPage: 1,
                                            gap: '0',
                                        } : {
                                            arrows: false,
                                            pagination: false,
                                            rewind: true,
                                            type: 'slide',
                                            autoplay: true,
                                            perPage: 2,
                                            gap: '.1em',
                                            drag: 'free',
                                            speed: 0,
                                            interval: 2000,                                            
                                            start: 1,
                                            snap: true,
                                            resetProgress: false,
                                        }} >

                                        {Object.entries(object[1]).map((img, ind, _ar) => (
                                            <SplideSlide className={img[1].includes('mp4') ? 'vidSlide' : 'imgSlides'} key={ind}>
                                                {
                                                    img[1].includes('mp4') ?
                                                        <video
                                                            controls
                                                            src={img[1]}
                                                            type='video/mp4'
                                                            autoPlay
                                                            muted
                                                        >
                                                            Your browser does not support HTML video.
                                                        </video>
                                                        :

                                                        <a className='thumbnailLink' href={img[1]}>
                                                            <Image thumbnail src={img[1]} alt={'image of ' + img[0]} name={img[0]} className='images'  />
                                                        </a>

                                                }
                                            </SplideSlide>
                                        ))}
                                    </Splide>
                                </SplideSlide>
                            ))
                            :
                            <h1>LOADING...</h1>
                    }
                </Splide>
                <Outlet />
            </div>
        );
    }
}