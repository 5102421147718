import '../../Assets/CSS/App.css';
import EyeGLB from '../../Assets/GLTF/eyesWithBackgroundglb.glb';
import * as THREE from 'three';
import MoveEyes from './MoveEyes.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { onWindowResize } from '../Utils/GlobalFunctions.js';

export const MODEL = {
    eyeScene: null,
    camera: null,
    canvasEl: null,
    scene: null,
    renderer: null,
    prevClip: "",
    x: 0,
    y: 0,
    animations: {
        mixer: null,
        aboutMe: {
            clips: [],
            actions: []
        },
        web: {
            clips: [],
            actions: []
        },
        vr: {
            clips: [],
            actions: []
        }
    }
};
var anims;
//var imgEl;
export function setEyes() {
    
    var loadEnded = new Event('loadend');
    //var previousTime = 0;
    var modelData = {
        scale: 1
    }

    setMainScene();

    document.addEventListener("loadend", finishSetup, false);

    loadModel();

   // animate();

    function setMainScene() {
        MODEL.canvasEl = document.getElementById('canvasEl');
        MODEL.scene = new THREE.Scene();
        MODEL.scene.name = 'eyeScene';

        addLights();
    }

    function setCanvas() {
        //alpha: true sets the scene background transparent  
        MODEL.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        MODEL.renderer.setSize(MODEL.canvasEl.clientWidth, MODEL.canvasEl.clientHeight);
        MODEL.renderer.gammaInput = true;
        MODEL.renderer.gammaOutput = true;

        MODEL.canvasEl.appendChild(MODEL.renderer.domElement);

       
    }

    function addLights() {
        //let intensity = 2;
        let light = new THREE.AmbientLight(0xFFFFFF, 0.25);
        //let light2 = new THREE.SpotLight(0xFFFFFF, intensity);

        //MODEL.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 1, 10000);
        //MODEL.camera.position.set(0, 0, 2);

        light.name = 'ambient_light';
        light.castShadow = true;
       /*  light2.castShadow = true;
        light2.distance = 20;
        light2.penumbra = .5;
        light2.position.set(-2.7, 1.85, 0.5); // ~60º
        light2.name = 'main_light'; */
        //light2.intensity = 10;

        MODEL.scene.add(light);
        //MODEL.scene.add(light2);

        
    } 

    /* function animate() {
        requestAnimationFrame(animate);
        var time = Date.now();
        var delta = time - previousTime;
        var { animations, renderer, scene, camera } = MODEL;
        var mixer = animations.mixer;

        mixer && mixer.update(delta / 1000);

        previousTime = time;
        renderer.render(scene, camera);
    } */

    function loadModel() {

        const loader = new GLTFLoader();
        loader.setCrossOrigin('anonymous');

        loader.load(EyeGLB, (gltf) => {
            setCanvas();
            let eyeScene = MODEL.eyeScene = gltf.scene || gltf.scenes[0];
            //eyeScene.animations = gltf.animations;
            eyeScene.name = "eyeScene";
            eyeScene.receiveShadow = true;

            //eyeScene.scale.set(modelData.scale, modelData.scale, modelData.scale);
            //eyeScene.
           
            MODEL.scene.add(eyeScene);

            MODEL.animations.mixer = new THREE.AnimationMixer(eyeScene);

             eyeScene.traverse((object) => {                
                if (object.isCamera) {
                    MODEL.camera = object;                    
                }   
                if(object.isLight) {
                    object.intensity = 100;
                }            
                
            });

            
            //MODEL.camera.position.set(0, 0, 10);

            MODEL.renderer.render(MODEL.scene, MODEL.camera)

            document.dispatchEvent(loadEnded);

        }, undefined, (err) => { console.log('error loading Model: ', err) });
    }

    function finishSetup() {
    
        addEventListeners();

        document.removeEventListener("loadend", finishSetup);
    }

    /* function SetEyesForAnimation() {
        MODEL.canvasEl = document.querySelector("#canvasEl");
        MODEL.x = window.innerWidth / 2;
        MODEL.y = window.innerHeight / 2;
    } */

    function addEventListeners() {        
        document.addEventListener('mousemove', e => {   
            
            MoveEyes(e, MODEL);
        });

        window.addEventListener("resize", (e) => {
            onWindowResize(MODEL.renderer, MODEL.camera, MODEL.canvasEl);
        });
    }

}

export function getAnims() {
    return anims;
}

export function updateEyes(winHalf) {
    MODEL.x = winHalf.x;
    MODEL.y = winHalf.y;
}