import * as React from "react";
import '../Assets/CSS/HomePage.css';
import SplideCarousel from "./SplideCarousel";


export default class SimpleHomePage extends React.Component {

    componentDidMount() {
      
    }

    render() {
        return (

            <div id="homepageContainer" className="pageActive">  
                <SplideCarousel/>
                <div className="categoryTitle">
                    CATEGORIES
                </div>
                
            </div>
        );
    }
}