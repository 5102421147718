import * as React from 'react';
import '../Assets/CSS/nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, NavLink } from 'react-bootstrap';
import { Link } from 'react-router';

export default class MyNavbar extends React.Component {

    render() {
        return (
            <Navbar className="navMain" fixed='top' >
                <Nav variant="pills" defaultActiveKey="/">
                    <NavLink as={Link} to={'/'}>|| HOME</NavLink>
                    <NavLink as={Link} to={'/pages/Comics'} state={{id: 'Comics'}}>| COMICS</NavLink>
                    <NavLink as={Link} to={'/pages/Caricatures'} state={{id: 'Caricatures'}}>| CARICATURES</NavLink>
                    <NavLink as={Link} to={'/pages/Portraits'} state={{id: 'Portraits'}}>| PORTRAITS</NavLink>
                    <NavLink as={Link} to={'/pages/Nature'} state={{id: 'Nature'}}>| NATURE</NavLink>
                    <NavLink as={Link} to={'/pages/LogosDesigns'} state={{id: 'LogosDesigns'}}>| LOGOS AND DESIGNS</NavLink>
                    <NavLink as={Link} to={'/pages/VR'} state={{id: 'VR'}}>| VR ||</NavLink>
                </Nav>
            </Navbar>
        )
    }
}