import * as THREE from 'three';
import { /* percentage ,*/ resetImagePositions, setPercentage } from '../Utils/GlobalFunctions';

const FOLDERS = ['Comics', 'Caricatures', 'Portraits', 'Nature', 'LogosDesigns', 'VR'];
export var IMG_URLS;
export const Carousel = new THREE.Group();


export function fetchImageURLs(folders = FOLDERS) {
    let imgFolderURLs = folders.reduce((acc, folder) => {
        let context;
        switch (folder) {
            case 'Comics': context = require.context('../../Assets/Images/Carousel/Comics', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Caricatures': context = require.context('../../Assets/Images/Carousel/Caricatures', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Portraits': context = require.context('../../Assets/Images/Carousel/Portraits', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'Nature': context = require.context('../../Assets/Images/Carousel/Nature', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'LogosDesigns': context = require.context('../../Assets/Images/Carousel/LogoDesign', false, /\.(png|jpe?g|svg)$/);
                break;
            case 'VR': context = require.context('../../Assets/Images/Carousel/VR', false, /\.(png|jpe?g|svg|mp4)$/);
                break;
            default:
        }
        acc[folder] = importAll(context);
        return acc;
    }, {});
    return imgFolderURLs;
}

export function fetchSingleCategory(folder) {   
    return fetchImageURLs(folder);
}

export function fetchAllCategories() {  
    IMG_URLS =  fetchImageURLs();
    return IMG_URLS;
}

function importAll(r) {
    let imgs = {};
    r.keys().map((item, index) => {
        let x = item.replace('./', '');
        x = x.replace(/\.(png|jpe?g|svg|mp4)$/, '')

        imgs[x] = r(item);
        return null;
    });

    return imgs;
}

export function setPlanetImages(planet) {

    planet.traverse(child => {
        //get the label mesh to create new img group
        if (child.isMesh && child.geometry.type === 'TextGeometry') {
            const imgGroup = new THREE.Group();
            imgGroup.userData.isImgGroup = true;
            planet.userData.labelName = child.name;
            imgGroup.name = child.name.split('_')[0];
            imgGroup.position.y -= planet.userData.radius / 4;
            imgGroup.rotation.set(0, 0, 0);
            planet.add(imgGroup);

            setImages(imgGroup, planet.userData.radius);
        }
    });
}

function setImages(imgGroup, radius) {
    const images = IMG_URLS[imgGroup.name];
    console.log('group images', images);
    if (images === undefined) return;

    const manager = new THREE.LoadingManager();
    const textureLoader = new THREE.TextureLoader(manager);
    let perc = 0;

    const numberOfImages = Object.keys(images).length;

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        const el = document.querySelector('#loading');
        perc = Math.trunc((itemsLoaded / numberOfImages * 100) / 2);
        const prog = 'Loading Image Textures... ' + perc + '%.';
        el.innerText = prog;
        setPercentage(perc);
    };

    manager.onLoad = function () {
        resetImagePositions(imgGroup);
    };

    /*
    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onError = function (url) {
        console.log('There was an error loading ' + url);
    };*/

    if (numberOfImages === 0) return;


    // Load all textures   
    Object.keys(images).map((img) => {
        textureLoader.load(images[img], (texture) => {
            createImages(texture, imgGroup, radius, img);
        });
        return null;
    })
}

function createImages(texture, imgGroup, radius, img) {
    const height = radius * 0.75;
    const aspectRatio = texture.image.width / texture.image.height;
    const width = height * aspectRatio;
    const cardGeometry = new THREE.PlaneGeometry(width, height);

    //Not sure why rotation adjustment is needed???            
    cardGeometry.rotateY(Math.PI);
    //////////////////////////////////////////////////

    const cardMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(cardGeometry, cardMaterial);
    mesh.name = img;
    mesh.material.transparent = true;
    mesh.material.opacity = 0;
    mesh.userData.isImage = true;
    mesh.userData.group = imgGroup.parent.name;
    mesh.layers.set(1);
    imgGroup.add(mesh);
}


