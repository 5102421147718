import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/CSS/App.css';
import { Link, useLocation } from 'react-router-dom';

export default function () {

    const location = useLocation();
    console.log("location ", location);
    const { id, src, prevPath } = location.state;

    return (
        <div id='imgContainer' >
            <div id='imgBackground' />
            <Link to={prevPath.path} state={{ id: prevPath.id }}>
                <button id='closeButton'>X</button>
            </Link>
            <div id='imgWrapper'>
                <img src={src} alt={'close-up of selected image: ' + id} />
            </div>
        </div>
    )
}