
export default function MoveEyes(e, model) {
    var camera = model.camera;
    var eyes = model.eyeScene.children[2].children;
    var scene = model.scene;
    var renderer = model.renderer;
    //let speed = 2;
    //let target = new THREE.Vector3();
    //target = getTarget(e);
   
    for (let i = 0; i < eyes.length; i++) {
        let eye = eyes[i];  
         
        if(eye.name.includes("Eye")) {
            eye.lookAt(e.clientX/window.innerWidth -0.25, e.clientY/window.innerHeight * -1 + 0.5, 0.75);
            renderer.render(scene, camera);
        }
    }
    
    /* function getTarget(e) {
        let target = new THREE.Vector3();
        let vec = new THREE.Vector3();
        let distance;

        vec.set(
            (e.clientX / window.innerWidth) * speed * -1,
            (e.clientY / window.innerHeight) * speed,
            -1);

       vec.unproject(camera);

        vec.sub(camera.position).normalize();

        distance = camera.position.z / vec.z;

        console.log("distance is ", distance)
        target.copy(vec).add(vec.multiplyScalar(distance));
            target = vec;
        console.log("target", target)

        return target;
    } */
}