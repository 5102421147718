import '../../Assets/CSS/App.css'
import * as THREE from 'three';


import { MODEL } from '../THREEJS/Eyes';

export let percentage = 0;

let previousMaxZPosition = 0;

export function setPercentage(perc) {
    percentage = perc;
}

export function getPercentage() {
    return percentage;
}

export function resetImagePositions(imgGroup) {

    const planet = imgGroup.parent;
    const radius = planet.userData.radius;
    const numImages = imgGroup.children.length;

    imgGroup.position.set(0, 0, radius * 0.1);
    imgGroup.rotateY(0);

    imgGroup.children.forEach((img, i) => {
        const angle = (i / numImages) * Math.PI * 2;
        const majorRad = radius * 2;
        const minorRad = radius / 1.5;
        img.userData.rad = { major: majorRad, minor: minorRad };
        img.userData.angle = angle;

        updateImagePosition(img, planet);
    });
}

export function updateImagePosition(img, planet) {
    const x = img.userData.rad.minor * Math.sin(img.userData.angle);
    const z = img.userData.rad.major * Math.cos(img.userData.angle);
    img.position.set(
        x,
        0,
        z
    );
    const scale = .3;
    img.scale.set(scale, scale, scale);
    img.userData.scale = scale;
    img.lookAt(planet.position);

    planet.userData.maxZimgPos = getMaxZPositionOfImages(img);
}
//const y = 10;
/* function getX(x) {

    if (x < y) {
        x = y;
    }
    y = x;
    return x;
} */

function getMaxZPositionOfImages(img) {
    let res;

    if (img.position.z > previousMaxZPosition) {
        res = img.position.z;
    }
    else {
        res = previousMaxZPosition;
    }

    previousMaxZPosition = res;
    console.log('img pos is ', res);
    return res;
}

export function onWindowResize(renderer, camera, el, e) {
    var winHalf;
    if (e) {
        let aspect = (el.clientWidth / el.clientHeight);
        camera.aspect = aspect;
        camera.updateProjectionMatrix();
        let x = window.innerWidth / 2;
        let y = window.innerHeight / 2;
        winHalf = {
            x: x,
            y: y
        }
    }

    console.log('resizing!!!!!!!!!!!!!!!!!!!!!!!!')
    renderer.setSize(el.clientWidth, el.clientHeight);
    renderer.render(MODEL.scene, camera);

    return winHalf;
}


export function setObjectPositionToFOV(object, camera) {

    if (!object) return;

    const isImage = object.userData.isImage;
    let endPosition;
    let fov = (camera.fov * Math.PI) / 360;
    let direction = new THREE.Vector3(0, 0, 1).normalize();
    // Get the camera's world direction        


    if (!isImage) {
        const radius = object.userData.radius;
        const distanceFromCenter = radius / Math.sin(fov);
        endPosition = new THREE.Vector3().copy(object.position).add(direction.multiplyScalar(distanceFromCenter));
    } else {
        const boundingBox = new THREE.Box3().setFromObject(object);
        const size = boundingBox.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);
        camera.getWorldDirection(direction);
        direction.normalize();
        const cameraAspect = camera.aspect;
        const distance = (maxDim / (2 * Math.tan(fov / 2)) * cameraAspect) / 3.75;
        // Set the new position of the object
        endPosition = new THREE.Vector3().copy(camera.position).add(direction.multiplyScalar(distance));
    }

    console.log('endPosition', endPosition);

    return endPosition;
}

export function raycast(event, targets, callback) {
    const mouse = getNormalizedMouseCoords(event, MODEL.renderer);
    const camera = MODEL.camera;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    raycaster.layers.set(0);
    raycaster.far = 100000;
    raycaster.near = 0.1;
    //debugRaycaster(raycaster);
    const intersects = raycaster.intersectObjects(targets, true);
    let result = null;
    if (intersects.length > 0) {
        result = intersects[0];
    }


    callback(result);

    function getNormalizedMouseCoords(event) {
        const mouse = new THREE.Vector2();
        const rect = MODEL.renderer.domElement.getBoundingClientRect();
        /*
        //must use offsetX and Y if not using window as renderer.domElement, otherwise it will be inacurate
        */
        mouse.x = ((event.offsetX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.offsetY - rect.top) / rect.height) * 2 + 1;
        return mouse;
    }
}

export function debugRaycaster(raycaster) {
    const geometry = new THREE.SphereGeometry(1);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(raycaster.ray.origin);
    MODEL.scene.add(sphere);

    // Optional: show ray direction
    const arrow = new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 5, 0xff0000, 0.1, 0.1);
    MODEL.scene.add(arrow);
}

/*export function loadingManager(manager, params) {
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
        console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onLoad = function () {
        console.log('All items loaded.');

    };
    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    };
    manager.onError = function (url) {
        console.log('There was an error loading ' + url);
    };
}*/

